<template>
  <div :style="slidePosition" class=" w-100 h-100 ">
    <div class="position-absolute d-flex flex-column align-items-start justify-content-center z-3 spiralWrapper6"
      style=" width: 148vw; height: 148vw; top:40%; left:70%;transform: translate3d(-50%, -50%,0);-webkit-backface-visibility: hidden; opacity: 0;">
      <img src="images/Slide6/spirals-centered.svg" class="spiral6c w-100 h-100" width="271" height="183"
        style="transform: translate3d(0,0,0);" aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate6a"
      style=" width: 68vw; height: 68vw; top:50%; left:70%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine1.svg" class="w-100 h-100" width="233" height="233" aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate6b"
      style=" width: 100vw; height: 100vw; top:50%; left:60%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine2.svg" class="w-100 h-100" width="233" height="233" aria-label="Yellow Spiral" />
    </div>
    <div
      class="position-absolute d-flex flex-column align-items-start justify-content-center rounded-circle circle6 z-4 border-warning"
      style="background: rgb(0,161,237); width: 55vw; height: 55vw; top:40%; left:70%; padding: 8vw; background: rgb(0,161,237); background: linear-gradient(0deg, rgba(0,161,237,1) 0%, rgba(0,108,169,1) 85%); transform: translate(-50%, -50%) scale(0); border:solid 0.75vh;">
      <h6 class="text-white font-vw-3  fade6" style="opacity:1; ">
        1970
      </h6>
      <h6 class="text-white font-vw-2-5 my-0 fade6" style="opacity:1; ">
        HEAVY LIFTING
      </h6>
      <p class="mb-0 mt-3 text-6 text-white font-vw-1-25" style="opacity:1; ">
        Where do you get cyclotrons built? Look no further than nearby Richmond,
        British Columbia-based Ebco Industries, the manufacturer of the
        18-metre-long diameter vacuum tank of the 520 MeV cyclotron. This early
        partnership paved the way for the production of the TRIUMF-designed
        TR-series of commercial medical cyclotrons by Ebco spin-off Advanced
        Cyclotron Systems Inc. These compact cyclotrons, such as the TR-30-1 and
        TR-30-2 installed at TRIUMF in 1990 and 2003 respectively, remain among
        the world’s most technologically advanced commercial cyclotrons.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  data() {
    return {}
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    circle6(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 6000
      })

      animation
        .add({
          targets: ".circle6",
          scale: [0, 1],
          translate: ("50%", "0%"),
          duration: 100,

          delay: (el, i) => 5000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade6",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 1000 + 30 * i
        })
        .add({
          targets: ".text-6",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    },
    spiralWrapperFade(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 200
      })

      animation
        .add({
          targets: ".spiralWrapper6",
          opacity: [0, 1],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".spiralWrapper6",
          opacity: [1, 0],
          duration: 400,
          delay: 0,
          easing: "linear"
        })

      animation.seek(animation.duration * percentage)
    },
    startLoop() {
      anime({
        loop: true,
        targets: ".rotate6a",
        rotate: "-5turn",
        direction: "alternate",
        duration: 80000,
        easing: "linear"
      })
      anime({
        loop: true,
        targets: ".rotate6b",
        rotate: "5turn",
        direction: "alternate",
        duration: 130000,
        easing: "linear"
      })
    }
  },
  mounted() {
    this.startLoop()
  },
  watch: {
    innerPosition() {
      this.spiralWrapperFade(this.innerPosition)
      this.circle6(this.innerPosition)
    }
  }
}
</script>

<style></style>
